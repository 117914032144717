import { Component } from 'react';
import classNames from 'classnames/bind';

import { TABLET, MOBILE, DESKTOP } from '../../../data/screenSizes';

import StorageSvc from '../../../services/StorageSvc';

import { stringToBool } from '../../../helpers/commonHelpers';

import withComponentName from '../../../decorators/withComponentName';
import withRouter from '../../../decorators/withRouter';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class ToggleBtn extends Component {
  componentDidUpdate() {
    const { checkForMobile, mode, opened } = this.props;

    if ([TABLET, MOBILE].includes(mode)) {
      if ((checkForMobile && opened)
        || ((stringToBool(StorageSvc.getItem('mobilePopupSeen')) && StorageSvc.getItem('promoID')) && opened)) {
        document.body.style.overflow = 'auto';
      } else if (!checkForMobile && opened) {
        document.getElementsByTagName('header')[0]?.style.removeProperty('position');
        document.getElementById('watchlist-header')?.style?.removeProperty('z-index');
      } else if (!checkForMobile && !opened) {
        document.getElementsByTagName('header')[0]?.style.removeProperty('position');
        document.getElementById('watchlist-header')?.style?.removeProperty('z-index');
      } else {
        document.body.style.removeProperty('overflow');
        document.documentElement.style.removeProperty('overflow');
        document.getElementById('watchlist-header')?.style?.removeProperty('z-index');
      }
    } else if ([DESKTOP].includes(mode)) {
      document.body.style.removeProperty('overflow');
      document.documentElement.style.removeProperty('overflow');
      document.getElementById('watchlist-header')?.style?.removeProperty('z-index');
    }
  }

  render() {
    const {
      opened, lightLeftBar,
      homepageLeftbarOpened, mode,
      promobarType, onClick, isHomePage,
      checkForMobile, toggleBtnRef, location, isOnboarding,
    } = this.props;

    const className = cx('toggle-button', {
      'is-cross': homepageLeftbarOpened && [DESKTOP].includes(mode) ? homepageLeftbarOpened : opened,
      'light-menu-toggle': lightLeftBar,
      'home-page': isHomePage,
      'promobar-desktop': promobarType === 'desktopPromo',
      'promobar-mobile': promobarType === 'mobilePromobar',
      'promobar-fixed': checkForMobile,
      onboarding: isOnboarding,
      active: opened,
    });

    return (
      <button
        id="menuToggleButton"
        aria-label="menu-toggle-button"
        type="button"
        className={className}
        onClick={(e) => onClick(e)}
        ref={toggleBtnRef}
      >
        <span />
      </button>
    );
  }
}

export default withComponentName(withRouter(ToggleBtn));
